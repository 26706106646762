<template>
  <router-link :to="url">
    <div class="sport-card" ref="bg" :class="mode">
        <h3 v-if="mode !== 'list' && mode !== 'live'"> {{sport.name}} </h3>

        <div class="content" v-if="mode === 'live'">
            <div class="live-badge"><svg> </svg> LIVE </div>
            <div class="logos" v-if="type == 'single'">
                <img loading="lazy" v-for="(c, i) in sport.competitions[0].competitors" :key="i" :src="c.team.logo"/>
            </div>

            <div class="logos" v-else>
                <img v-if="sport.name.includes('UFC')" :src="ufcLogo()" loading="lazy"/>
                <img v-else :src="f1Logo()" loading="lazy"/>
            </div>
            <div class="details" v-if="sport.competitions[0].competitors[0].team">
                <span>
                        {{ sport.competitions[0].competitors[0].team.shortDisplayName }}
                    <b>
                    {{ sport.competitions[0].competitors[0].score }}
                    </b>
                </span>
                <span>
                        {{ sport.competitions[0].competitors[1].team.shortDisplayName }}
                    <b>
                    {{ sport.competitions[0].competitors[1].score }}
                    </b>
                </span>

            </div>
            <div class="details" v-else>
                <span> <b> </b> </span>
                <span> {{sport.shortName}} <b> </b> </span>
            </div>

        </div>
        <div class="content" v-else>
            <div class="logos" v-if="type == 'single'">
                <img loading="lazy" v-for="(c, i) in sport.competitions[0].competitors" :key="i" :src="c.team.logo"/>
            </div>
            <div class="logos" v-else>
                <img v-if="sport.name.includes('UFC')" :src="ufcLogo()" loading="lazy"/>
                <img v-else :src="f1Logo()" loading="lazy"/>
            </div>
            <h3 v-if="mode == 'list'"> {{sport.shortName}} </h3>
            <p> {{score}} </p>
            <p> {{timestring()}} </p>
        </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'live',
  props: {
    sport: Object,
    mode: String,
  },

  computed: {
    type(){
        if(this.sport.name.includes('UFC') || this.sport.name.includes('Prix')){
            return 'multi'
        }else{
            return 'single'
        }
    }
  },

  data: function(){
    return {
        url: '',
        isEventLive: false,
        score: '',
        loaded: false
    }
  },

  methods: {
    getURL(){
        let url = window.sportsData.find((x) => {
            if(x.name == this.sport.type){ return x }
        })
        this.url = `/sport/${url.url}/${this.sport.id}`
    },
    formatTime(time){
        let date = new Date(time)
        let hours = String(date.getHours()).padStart(2, '0')
        let min = String(date.getMinutes()).padStart(2, '0')
        return `${hours > 12 ? hours - 12 : hours }:${min}`
    },

    timestring(){
        return `${this.sport.status.type.description}`
    },
    f1Logo(){
        return `https://www.formula1.com/content/dam/fom-website/2018-redesign-assets/Track%20icons%204x3/${this.sport.circuit.address.country}%20carbon.png.transform/9col/image.png`
    },
    ufcLogo(){
        return 'https://deltafonts.com/wp-content/uploads/UFC-LOGO.png'
    },
    setFallbackImageUrl(event) {
        event.target.src = ''
    },

    onLoaded: function() {
      this.loaded = true;
    },
  },

  mounted: function(){
      this.$nextTick(() => {
        this.getURL()
        let competitions = this.sport.competitions
        if(competitions[0] && competitions[0].competitors && competitions[0].competitors[0].team && competitions[0].competitors[1].team){
            this.score = `${competitions[0].competitors[0].score} - ${competitions[0].competitors[1].score}`
            this.$refs.bg.style.backgroundImage = `linear-gradient(-45deg, #${competitions[0].competitors[0].team.color}, #${competitions[0].competitors[0].team.alternateColor}, #${competitions[0].competitors[1].team.color}, #${competitions[0].competitors[1].team.alternateColor})`
        }
      })
  }

}
</script>

<style scoped lang="scss">
a{
    text-decoration: none;
}
.sport-card{
    display: flex;
    height: 100%;
    flex-direction: column;
    color: black;
    text-decoration: none;
    background-color: rgba(white, 0.4);
    color: white;
    border-radius: 5px;
    align-items: flex-start;
    justify-content: space-between;
    background-position: 50% 50%;
    padding: 10px;
    box-sizing: border-box;
    background-image: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
    overflow: hidden;
    z-index: 0;
    .content{
        width: 100%;
        height: auto;
    }
    // :after{
    //     content: ' ';
    //     position: absolute;
    //     height: 100%;
    //     width: 100%;
    //     top: 0;
    //     left: 0;
    //     opacity: 0.2;
    //     z-index: -1;
    // }

    &.list{ 
        animation: none!important;
        :after{
            opacity: 0.1;
        }
    }

    @keyframes gradient { 0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    h3{
        font-size: 12px;
        font-weight: bold;
        width: 85%;
        text-transform: uppercase;
        line-height: 1.7;
    }
    span{
        font-weight: bold;
        font-size: 14px;
        padding-top: 10px;
    }
    p{
        margin-top: 10px;
        text-align: right;
        font-size: 9px;
    }

   .logos{
       display: flex;
       flex-direction: row-reverse;
       background-color: transparent;
       position: relative;
       height: 100%;

       img{
           margin: 5px;
           width: 40px;
           object-fit: contain;
           height: 100%;
       }
   }

    &.list{
        height: 50px;
        min-width: 300px;
        flex-direction: row;
        align-items: center;
        border-radius: 4px;

        img{
            width: 25px;
        }

        > div{
            width: 100%;
            flex-direction: row;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        h3{
            font-size: 9px;
            width: auto;
        }
    }


    @keyframes pulse {
      0%   { opacity: 1; }
      50%  { opacity: 0; }
      100% { opacity: 1; }
    }

    &.live{
        height: 200px;
        width: 150px;
        margin-right: 10px;
        .content{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
        }

        .logos{
            padding-top: 20px;
            padding-bottom: 20px;
            flex-direction: row;
            img{
                width: 50px;
            }
        }

        .details{
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0 10px;
            height: 25px;
            box-sizing: border-box;
            span{
                display: flex;
                flex-direction: row;
                min-width: 100%;
                flex-grow: 1;
                justify-content: space-between;
                margin-top: 0px;
                font-size: 11px;;
                b{
                    flex-grow: 1;
                    text-align: right;
                }
            }
        }
        .live-badge{
            display: flex;
            font-size: 7px;
            letter-spacing: 1px;
            color: white;
            padding: 4px 0;
            max-width: 50px;
            width: 50px;
            margin-right: 100px;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background-color: rgba(black, 0.5);
            svg{
                width: 5px;
                height: 5px;
                background-color: #eb2f06;
                border-radius: 50%;
                margin-right: 5px;
                animation: pulse 1s infinite;
            }
        }
    }

}

</style>
